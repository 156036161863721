::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: var(--color-gray-200);
}

.custom-scroll {
  overflow: auto;
  scrollbar-color: hsla(0deg 2% 42% / 21%) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
}
