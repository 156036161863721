@import 'button';
@import '@/components/messages/message';
@import 'table';

.custome-timeline {
  .ant-timeline-item {
    .ant-timeline-item-label {
      color: var(--color-sub-text);
      width: 42px;
      font-size: 14px;
      text-align: start;
    }

    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      inset-inline-start: 64px;
    }

    &.ant-timeline-item-left .ant-timeline-item-content {
      inset-inline-start: 64px;
      width: calc(100% - 92px);
    }
  }
}

.data-field-picker {
  .ant-picker.ant-picker-range {
    position: absolute;
    left: -9999px;
  }

  .ant-picker-dropdown.ant-picker-dropdown-range {
    position: relative !important;
    inset: 0 !important ;
    top: 0 !important;
    left: 0 !important;

    .ant-picker-range-arrow {
      display: none;
    }
  }

  button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panel-container.ant-picker-date-panel-container {
    box-shadow: none;
  }
}

.custom-menu-dropdown.ant-dropdown-menu {
  .ant-dropdown-menu-submenu-title {
    align-items: center;
  }
}
