.setting-sidebar {
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-content-holder {
    display: none;
  }

  .ant-tabs-tab-active {
    background: var(--color-primary-250);
  }
}

.main {
  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label > label {
      color: var(--color-sub-text);
      font-weight: 500;
      font-size: 14px;
    }

    .ant-form-item-explain-error {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .ant-form-item-label > label.ant-form-item-required {
      &::before {
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }
}
