.full-screen-gradient {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(68.75% 68.75% at 39.29% 10.71%, #b7f2ff 0%, #ffe27c 100%),
    radial-gradient(75% 75% at 75.89% 25%, rgb(210 255 223 / 50%) 72.5%, rgb(70 255 122 / 50%) 100%),
    linear-gradient(180deg, rgb(30 54 87 / 0%) 0%, rgb(30 54 87 / 1%) 100%),
    linear-gradient(180deg, rgb(255 255 255 / 0%) 70.48%, #fff 93.62%),
    linear-gradient(180deg, rgb(246 246 246 / 0%) 0%, #f6f6f6 100%);
  background-blend-mode: overlay, overlay, overlay, overlay, normal;
}

.custom-login-modal.ant-modal {
  .ant-modal-content {
    border-radius: 1rem;
  }

  .ant-modal-header {
    border-radius: 1rem 1rem 0 0;
  }
}

.ant-otp {
  .ant-otp-input {
    width: 36px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--color-border);

    &:focus-within {
      box-shadow: none;
    }
  }
}
