.custom-table {
  .ant-table-thead {
    .ant-table-cell {
      background-color: var(--color-background);
      font-size: 16px;
      color: var(--color-sub-text);
      font-weight: normal;
      padding: 16px 12px;
    }
  }
}
