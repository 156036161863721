@import 'antd/antd';
@import 'tailwind/tailwind';
@import 'common/scrollbar';
@import 'common/chat';
@import '@/modules/public-modules/styles';
@import '@/modules/settings';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
}

// Hide body overflow to prevent pull to refresh on mobile
@media (max-width: 768px) {
  body {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.shadow-top {
  box-shadow: 0 -2px 16px rgba(0, 0, 0, 20%);
}

#privacy-policy {
  font-size: 12pt;
  line-height: 1.5;
  text-align: justify;

  h1,
  h2,
  h3 {
    page-break-after: avoid;
  }

  ul,
  ol {
    page-break-inside: avoid;
  }
}

::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
  display: block;
}

.dark::view-transition-old(root) {
  z-index: 1;
}

.dark::view-transition-new(root) {
  z-index: 999;
}

::view-transition-old(root) {
  z-index: 999;
}

::view-transition-new(root) {
  z-index: 1;
}
