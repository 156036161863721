.chat-content {
  display: none;
}

.chat-wrapper:hover .chat-content {
  display: flex;
}

.modal-otp {
  input.has-value {
    border-color: green;
  }

  input {
    border-width: 2px !important;
  }
}
